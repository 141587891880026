import React, { useState } from "react";
import { Link } from "gatsby" 
import Layout from "../components/layout";
import SEO from "../components/seo";
import useHorizontal from "@oberon-amsterdam/horizontal/hook";
import useWindowSize from "../hooks/useWindowSize";

function Arak() {
  const [container, setContainer] = useState();
  useHorizontal({ container: container });

  const { width } = useWindowSize();
  const breakpoint = 1024;

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Árak | GÁBOR TAMÁS"
      />
      {width > breakpoint ? (
        // CALC HEIGHT OF HORIZONTAL SCROLL TRACK
        <div
          ref={(ref) => {
            setContainer(ref);
          }}
          className="flex p-0 m-0"
          style={{ height: `calc(100vh - 192px)` }}
        >
          {/* WRAPPER TO POSITON BLOCKS TO MIDDLE */}
          <div className="flex flex-row my-auto font-normal text-black">
            {/* CONTENT BLOCK */}
            <div className="flex items-start max-w-3xl ml-32 mr-20 min-w-2/5 lg:min-w-5/12 xl:min-w-6/12 2xl:min-w-6/12 3xl:min-w-4/12">
              <h1 className="text-3xl font-black text-black md:text-5xl xl:text-5xl 2xl:text-6xl">
                Ha szakértelemmel készített ingatlan, építészeti vagy design fotózásra lenne szüksége, tekintse meg a portfóliómat és lépjen velem kapcsolatba.
              </h1>
            </div>
            <div className="flex flex-col items-start max-w-2xl pt-4 mr-10 min-w-2/5 xl:min-w-4/12 2xl:min-w-2/6 3xl:min-w-1/5">
              <h2 className="mb-8 text-xl font-bold text-black 3xl:text-2xl">
                Árajánlatkérés
              </h2>
              <p className="mb-8 font-normal leading-7 text-black md:text-xl xl:leading-8 lg:leading-8 lg:text-xl 2xl:leading-10 xl:text-lg 2xl:text-xl">
                Az alább feltűntetett árak reprezentatív jellegűek, pontos árajánlathoz minden esetben szükséges a projekt és igények részletes megismerése. Ezáltal tudok személyre szabott, a munka komplexitásának megfelelő árajánlatot kínálni. Keressen bizalommal a megadott elérhetőségeken keresztül, telefonon vagy emailben.
              </p>
              
              <Link to="/kapcsolat" className="inline-block px-10 py-4 mt-4 text-base font-semibold leading-none text-white transition duration-200 ease-in-out bg-indigo-500 rounded-md 2xl:font-medium 2xl:text-xl focus:outline-none hover:bg-indigo-600 lg:mt-0">
                Árajánlatot kérek
              </Link>
              {/* <p className="text-xl font-normal leading-7 text-black xl:leading-8 lg:leading-8 lg:text-xl 2xl:leading-10 xl:text-lg 2xl:text-xl">
                Főleg Budapesten vagyok elérhető, de szívesen fogadok megkereséseket országszerte és nemzetközi színten egyaránt.
              </p> */}
            </div>
            <div className="flex flex-row items-start max-w-6xl mx-6 mb-6 bg-blue-700 rounded-lg min-w-2/5 xl:min-w-2/4 3xl:min-w-1/5">
              <div className="p-8 bg-blue-700 rounded-lg">
                <h2 className="mb-3 text-2xl font-semibold tracking-normal text-white">
                  Prémium Ingatlan Fotók
                </h2>
                <h2 className="mb-5 text-3xl font-bold tracking-wide text-blue-100">
                  30,000 Ft-tól
                </h2>
                <p className="font-light leading-9 text-white md:text-xl xl:leading-7 lg:leading-8 lg:text-xl 2xl:leading-10 xl:text-base 2xl:text-xl">
                  Kisebb komlpexitású projektek, telefonos egyeztetés, 1-3 óra fotózás a helyszínen,
                  korrekciós utómunka, 24-48 órán belül átadott képek.
                </p>
              </div>
              <div className="w-full border-b border-gray-100"></div>
              <div className="px-10 py-8">
                <ul className="space-y-3 list-disc">
                  <li className="flex items-start">
                    <span className="flex items-center h-6 sm:h-7">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </span>
                    <p className="ml-2 text-lg font-light text-white">
                      Magán lakáshirdetésnek
                    </p>
                  </li>
                  <li className="flex items-start">
                    <span className="flex items-center h-6 sm:h-7">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </span>
                    <p className="ml-2 text-lg font-light text-white">
                      Ingatlan ügynökségi hirdetésnek
                    </p>
                  </li>
                  <li className="flex items-start">
                    <span className="flex items-center h-6 sm:h-7">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </span>
                    <p className="ml-2 text-lg font-light text-white">
                      Rövid távú lakáskiadáshoz
                    </p>
                  </li>
                  <li className="flex items-start">
                    <span className="flex items-center h-6 sm:h-7">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </span>
                    <p className="ml-2 text-lg font-light text-white">
                      Kiskereskedelmi helységeknek
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-2/5 px-8"></div>
          </div>
        </div>
      ) : (
        <div className="p-0 m-0">
          {/* WRAPPER TO POSITON BLOCKS TO MIDDLE */}
          <div className="mx-4 mt-10 mb-6 sm:mx-10">
            {/* CONTENT BLOCK */}
            <h1 className="mb-12 text-3xl font-black text-black md:text-5xl xl:text-5xl 2xl:text-6xl">
            Ha szakértelemmel készített ingatlan, építészeti vagy design fotózásra lenne szüksége, tekintse meg a portfóliómat és lépjen velem kapcsolatba.
            </h1>
            <h2 className="mb-4 text-xl font-bold 3xl:te xt-2xl">
            Árakkal kapcsolatosan
            </h2>
            <p className="mb-10 leading-9 md:text-xl xl:leading-8 lg:leading-8 lg:text-xl 2xl:leading-10 xl:text-lg 2xl:text-xl">
            Az alább feltűntetett árak reprezentatív jellegűek, pontos árajánlathoz minden esetben szükséges a projekt és igények részletes megismerése. Ezáltal tudok személyre szabott, a munka komplexitásának megfelelő árajánlatot kínálni. Keressen bizalommal a megadott elérhetőségeken keresztül, telefonon vagy emailben.
            </p>
            <div className="flex flex-col items-start bg-blue-700 rounded-lg">
              <div className="flex flex-col items-start p-8 bg-blue-700 rounded-lg">
                <h2 className="mb-3 text-2xl font-semibold tracking-wide text-white">
                  Ingatlanfotó
                </h2>
                <h2 className="mb-5 text-3xl font-bold tracking-wide text-blue-100">
                  45,000 Ft-tól
                </h2>
                <p className="font-light leading-9 text-white md:text-xl xl:leading-7 lg:leading-8 lg:text-xl 2xl:leading-10 xl:text-base 2xl:text-xl">
                  Kisebb komlpexitású projektek, telefonos egyeztetés, 1-3 óra fotózás a helyszínen,
                  korrekciós utómunka, 24-48 órán belül átadott képek.
                </p>
              </div>
              <div className="w-full border-b border-gray-100"></div>
              <div className="px-10 py-8">
                <ul className="space-y-3 list-disc">
                  <li className="flex items-start">
                    <span className="flex items-center h-6 sm:h-7">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </span>
                    <p className="ml-2 text-lg font-light text-white">
                      Magán lakáshirdetés
                    </p>
                  </li>
                  <li className="flex items-start">
                    <span className="flex items-center h-6 sm:h-7">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </span>
                    <p className="ml-2 text-lg font-light text-white">
                      Ingatlan ügynökségi hirdetés
                    </p>
                  </li>
                  <li className="flex items-start">
                    <span className="flex items-center h-6 sm:h-7">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </span>
                    <p className="ml-2 text-lg font-light text-white">
                      Airbnb hirdetés
                    </p>
                  </li>
                  <li className="flex items-start">
                    <span className="flex items-center h-6 sm:h-7">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </span>
                    <p className="ml-2 text-lg font-light text-white">
                      Booking.com hirdetés
                    </p>
                  </li>
                  <li className="flex items-start">
                    <span className="flex items-center h-6 sm:h-7">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </span>
                    <p className="ml-2 text-lg font-light text-white">
                      Kiskereskedelmi helységek
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default Arak;
